.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.card {
  flex: 1 1 calc(33.333% - 20px); /* Three cards per row with some spacing */
  max-width: calc(33.333% - 20px);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}

.card-img {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card-info {
  padding: 16px 24px 24px 24px;
}

.card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.card-description {
  font-size: 1rem;
  color: #555;
}


/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
