/* ContactPage.css */


.contact-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./images/wall.jpeg');
  background-size: cover;
  background-position: center;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.4);
  opacity: 0.9;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  opacity: 0.5; /* Adjust opacity as needed */
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin-bottom: +200px;
}

.text-container {
  width: 50%;
  padding: 20px;
  color: #fff;
}

.about-container {
  font-size: 2rem;
  font-weight: bold;
}

.form-input,
.form-textarea,
.submit-button {
  width: 100%;
  padding: 10px;
  margin-left: 50px;
  margin-bottom: 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #0a0a0a;
}

.form-textarea {
  height: 150px;
}

.submit-button {
  background-image: url('./images/send.png');
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #333;
  color: #fff;
}

.image-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coffee-image {
  width: 80%;
  border-radius: 5px;
}
