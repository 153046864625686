.about-container {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-top: 200px;
}

.star {
  background-image: url(./images/star.png);
  width: 30px;
  height: 20px;
  background-size: cover;
  background-position: center;
  opacity: 0.9;
  display: inline-block;
  margin-left: 5px;
  margin-top: -10px;
}

.text-animate-hover_aboutme {
  display: inline-block;

  font-weight: bold;
  font-size: 40px;

  color: rgb(54, 25, 77);
}
.space {
  margin: 0 5px;
}

.text-animate-hover_aboutme:hover {
  color: #a775ce;
  transform: translateY(-10px);
}

.about-description {
  margin-top: 20px;
}

.about-container h1 {
  color: #9156d4;
  font-size: 40px;
}

.about-description p {
  font-size: 16px;
  line-height: 1.6;
  font-size: 1.2rem;
  font-family: "Lora", serif;
  padding: 10px 70px 10px 70px;
  color: rgb(104, 107, 111);
}

.resume {
  background-image: url(./images/resume.png);
  width: 150px; 
  height: 40px; 
  background-size: cover; 
  background-position: center;
  border-radius: 80px;
  opacity: 0.9;
}

.resume:hover {
  opacity: 0.5;
}
/* For tablets (landscape) and smaller */
@media only screen and (max-width: 1024px) {
  .about-container {
    width: 80%; /* Adjust container width */
    margin-top: 150px; /* Adjust margin-top */
  }

  .text-animate-hover_aboutme {
    font-size: 30px; /* Adjust font size */
  }

  .about-container h1 {
    font-size: 30px; /* Adjust font size */
  }

  .about-description p {
    font-size: 1.2rem; /* Adjust font size */
    padding: 10px; /* Adjust padding */
  }

  .resume {
    width: 120px; /* Adjust button size */
    height: 30px; /* Adjust button size */
  }
}

/* For mobile devices */
@media only screen and (max-width: 768px) {
  .about-container {
    width: 90%; /* Adjust container width */
    margin-top: 100px; /* Adjust margin-top */
  }

  .text-animate-hover_aboutme {
    font-size: 24px; /* Adjust font size */
  }

  .about-container h1 {
    font-size: 24px; /* Adjust font size */
  }

  .about-description p {
    font-size: 1.2rem; /* Adjust font size */
    padding: 5px; /* Adjust padding */
  }

  .resume {
    width: 100px; /* Adjust button size */
    height: 25px; /* Adjust button size */
  }
}

/* Media Queries for Responsive Design */

/* For tablets (landscape) and smaller */
@media only screen and (max-width: 1024px) {
  .text-container {
    margin-top: 80px; /* Adjust margin-top for smaller screens */
  }

  .image-container {
    margin-top: 20px; /* Adjust margin-top for smaller screens */
  }
}

/* For mobile devices */
@media only screen and (max-width: 768px) {
  .text-container {
    margin-top: 50px; /* Adjust margin-top for mobile screens */
    width: 90%; /* Adjust width for better fit */
  }

  .form-input,
  .form-textarea,
  .submit-button {
    width: 100%;
  }

  .image-container {
    width: 100%;
    margin-top: 20px; /* Adjust margin-top for mobile screens */
  }

  .coffee-image {
    max-width: 300px; /* Adjust max-width for smaller screens */
  }
}