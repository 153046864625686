nav {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: rgb(251, 250, 253);
  /* Adjust as needed */
  z-index: 1000; /* Ensure it's above other content */
}
nav ul {
  list-style-type: none;
  padding: 20px;
  display: flex;
  font-family: "Lora", serif;
  margin: 0 auto;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.5;

  padding: 0.5rem 1rem;
}

.navbar-align {
  font-weight: bold;
  font-size: 15px;
  margin: 0 auto;
  padding: 10px;

  max-width: 900px;
}

nav ul li {
  padding-left: 50px;

  text-align: center;
  margin: auto;
  margin-top: 20px;
}

.nav-link {
  text-decoration: none;
  color: rgb(70, 66, 66);
  position: relative;
  &:hover {
    color: rgb(187, 144, 233);
  }
}

.nav-link span {
  position: relative;
  z-index: 1;
}

.line {
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: rgb(104, 73, 151);
  bottom: -2px;
  transition: width 0.3s ease;
}

.nav-link:hover .line {
  width: 100%;
}

.logo {
  background-image: url(./images/logoo.png);
  width: 80px; 
  height: 50px; 
  background-size: cover; 
  background-position: center;
  margin-right: 50px;
  margin-top: 10px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.line2 {
  position: absolute;
  width: 100%;
  height: 15px; /* Adjust line height as needed */
  background-color: rgb(194, 162, 243); /* Purple color */
  left: 0;
  top: 50%;
  transform: translateY(-50%); /* Center the line vertically */
  margin-top: -40px;
}

/* Tablet Styles */
@media (max-width: 768px) {
  nav ul {
    margin-left: -50px;
    font-size: 1rem;
    width: 100%;
  }
}

/* Mobile Styles */
@media (max-width: 576px) {
  nav {
    display: none; /* Adjust height as needed */
  }

  nav ul {
    margin-left: 50px;

    flex-direction: column;
    padding: 0;
  }

  nav ul li {
    padding: 10px 0;
  }

  .line2 {
    display: none; /* Show the line on mobile */
  }
}