/* Footer.css */

.footer {
  position: relative; /* Ensure the footer stays at the bottom */
  width: 100%; /* Occupy full width of the viewport */
  margin-top: auto; /* Push the footer to the bottom of the content */
  background-color: #4a235a; /* Dark purple */
  color: white;
  padding: 20px 0;
}

.upper-footer {
  height: 150px;
  background-color: #755e8f; /* Light purple */
}

.lower-footer {
  background-color: #4a235a; /* Dark purple */
  padding: 10px 0;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.footer-section-item {
  flex: 1;
  color: white;
  font-size: 1.3rem;
}

.footer-section-item p {
  font-size: 1.2rem;
  color: #d5cccc;
  margin-top: -20px; /* Adjust as needed */
}

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons a {
  margin-right: 10px;
  background-size: cover;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.social-icons .play-on {
  margin-left: 0.5px;
  margin-top: -10px;
  opacity: 0.8;
}

.social-icons .facebook {
  background-image: url(./images/facebook-icon.png);
}

.social-icons .mail {
  background-image: url(./images/mail-icon.png);
}

.social-icons .instagram {
  background-image: url(./images/instagram-icon.png);
}

.social-icons .github {
  background-image: url(./images/github-icon.png);
}

.social-icons .linkedin {
  background-image: url(./images/linkedin-icon.png);
}

.social-icons .medium {
  background-image: url(./images/medium-icon.png);
}

.social-icons .twitter {
  background-image: url(./images/twitter-icon.png);
}

.social-icons .reddit {
  background-image: url(./images/reddit-icon.png);
}

.footer-center {
  text-align: center;
  color: white;
  margin-top: 20px; /* Adjust margin-top */
}

.footer-center p {
  font-size: 1.1rem;
}

/* Media Queries for Responsive Design */

/* For tablets (landscape) and smaller */
@media only screen and (max-width: 1024px) {
  .footer-section {
    margin-left: 50px; /* Adjust margin-left */
  }

  .footer-section-item {
    font-size: 1rem; /* Adjust font size */
  }

  .footer-section-item p {
    font-size: 0.9rem; /* Adjust font size */
    margin-top: -10px; /* Adjust margin-top */
  }
}

/* For mobile devices */
@media only screen and (max-width: 768px) {
  .footer {
    position: relative; /* Reset position */
    bottom: 0; /* Ensure footer is at the bottom */
  }

  .upper-footer {
    height: auto; /* Allow height to adjust based on content */
    padding: 20px 0;
  }

  .lower-footer {
    margin-top: 20px; /* Adjust margin-top */
  }

  .footer-section {
    flex-direction: column; /* Stack items vertically */
    margin-left: 20px; /* Adjust margin-left */
    text-align: center; /* Center-align text */
  }

  .footer-section-item {
    font-size: 1rem; /* Adjust font size */
    margin-bottom: 10px; /* Adjust margin-bottom */
  }

  .footer-section-item p {
    font-size: 0.9rem; /* Adjust font size */
    margin-top: 5px; /* Adjust margin-top */
  }

  .social-icons {
    justify-content: center; /* Center-align social icons */
  }

  .social-icons a {
    margin-right: 5px; /* Adjust margin */
    margin-bottom: 10px; /* Adjust margin */
  }

  .footer-center {
    margin-top: 10px; /* Adjust margin-top */
  }

  .footer-center p {
    font-size: 0.9rem; /* Adjust font size */
    margin-top: -40px; /* Adjust margin-top */
  }

  .social-icons .play-on {
    margin-top: -5px; /* Adjust margin-top */
  }
}
