.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Gap between items */
}

.skill {
  background-size: contain;
  background-repeat: no-repeat;

}

.skill-skills {
  background-image: url('./images/photo-skills.png');
  width: 500px; /* Ensure all images are the same size */
  height: 500px;

}

  /* For tablets (landscape) and smaller */
@media only screen and (max-width: 1024px) {

 
 
  }
  
  /* For mobile devices */
  @media only screen and (max-width: 768px) {

  }
  